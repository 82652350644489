
// LOGIN TYPES ==============================
export const LOGIN_DATA_START = "LOGIN_DATA_START"
export const LOGIN_GET_COMPANY_DATA = "LOGIN_GET_COMPANY_DATA"
export const LOGIN_DATA_END = "LOGIN_DATA_END"



// HR OPERATIONS ===> FAMILY TYPES
export const GET_TRANSITION_FAMILY_DATA = "GET_TRANSITION_FAMILY_DATA";
export const GET_TRANSITION_FAMILY_START = "GET_TRANSITION_FAMILY_START";
export const GET_TRANSITION_FAMILY_DATA_SINGLE = "GET_TRANSITION_FAMILY_DATA_SINGLE";
export const GET_TRANSITION_FAMILY_END = "GET_TRANSITION_FAMILY_END";


// HR OPERATIONS ===> COST CENTER
export const GET_TRANSITION_COST_CENTRE_DATA = "GET_TRANSITION_COST_CENTRE_DATA";
export const GET_TRANSITION_COST_CENTRE_START = "GET_TRANSITION_COST_CENTRE_START";
export const GET_TRANSITION_COST_CENTRE_DATA_SINGLE = "GET_TRANSITION_COST_CENTRE_DATA_SINGLE";
export const GET_TRANSITION_COST_CENTRE_END = "GET_TRANSITION_COST_CENTRE_END";


// HR OPERATIONS ===> COUNTRY
export const GET_TRANSITION_COUNTRY_DATA = "GET_TRANSITION_COUNTRY_DATA";
export const GET_TRANSITION_COUNTRY_START = "GET_TRANSITION_COUNTRY_START";
export const GET_TRANSITION_COUNTRY_DATA_SINGLE = "GET_TRANSITION_COUNTRY_DATA_SINGLE";
export const GET_TRANSITION_COUNTRY_END = "GET_TRANSITION_COUNTRY_END";


// HR OPERATIONS ===> DEPARTMENT
export const GET_TRANSITION_DEPARTMENT_DATA = "GET_TRANSITION_DEPARTMENT_DATA";
export const GET_TRANSITION_DEPARTMENT_START = "GET_TRANSITION_DEPARTMENT_START";
export const GET_TRANSITION_DEPARTMENT_DATA_SINGLE = "GET_TRANSITION_DEPARTMENT_DATA_SINGLE";
export const GET_TRANSITION_DEPARTMENT_END = "GET_TRANSITION_DEPARTMENT_END";

// HR OPERATIONS ===> DESIGNATION
export const GET_TRANSITION_DESIGNATION_DATA = "GET_TRANSITION_DEPARTMENT_DATA";
export const GET_TRANSITION_DESIGNATION_START = "GET_TRANSITION_DEPARTMENT_START";
export const GET_TRANSITION_DESIGNATION_DATA_SINGLE = "GET_TRANSITION_DEPARTMENT_DATA_SINGLE";
export const GET_TRANSITION_DESIGNATION_END = "GET_TRANSITION_DEPARTMENT_END";

// HR OPERATIONS ===> BASE CITY
export const GET_TRANSITION_BASE_CITY_DATA = "GET_TRANSITION_BASE_CITY_DATA";
export const GET_TRANSITION_BASE_CITY_START = "GET_TRANSITION_BASE_CITY_START";
export const GET_TRANSITION_BASE_CITY_DATA_SINGLE = "GET_TRANSITION_BASE_CITY_DATA_SINGLE";
export const GET_TRANSITION_BASE_CITY_END = "GET_TRANSITION_BASE_CITY_END";

// HR OPERATIONS ===> DIVISIONS
export const GET_TRANSITION_DIVISIONS_DATA = "GET_TRANSITION_DIVISIONS_DATA";
export const GET_TRANSITION_DIVISIONS_START = "GET_TRANSITION_DIVISIONS_START";
export const GET_TRANSITION_DIVISIONS_DATA_SINGLE = "GET_TRANSITION_DIVISIONS_DATA_SINGLE";
export const GET_TRANSITION_DIVISIONS_END = "GET_TRANSITION_DIVISIONS_END";

// HR OPERATIONS ===> EDUCATION_LEVEL
export const GET_TRANSITION_EDUCATION_LEVEL_DATA = "GET_TRANSITION_EDUCATION_LEVEL_DATA";
export const GET_TRANSITION_EDUCATION_LEVEL_START = "GET_TRANSITION_EDUCATION_LEVEL_START";
export const GET_TRANSITION_EDUCATION_LEVEL_DATA_SINGLE = "GET_TRANSITION_EDUCATION_LEVEL_DATA_SINGLE";
export const GET_TRANSITION_EDUCATION_LEVEL_END = "GET_TRANSITION_EDUCATION_LEVEL_END";

// HR OPERATIONS ===> EDUCATION_LEVEL
export const GET_TRANSITION_EDUCATION_DATA = "GET_TRANSITION_EDUCATION_DATA";
export const GET_TRANSITION_EDUCATION_START = "GET_TRANSITION_EDUCATION_START";
export const GET_TRANSITION_EDUCATION_DATA_SINGLE = "GET_TRANSITION_EDUCATION_DATA_SINGLE";
export const GET_TRANSITION_EDUCATION_END = "GET_TRANSITION_EDUCATION_END";

// HR OPERATIONS ===> EMPOYLEE CAT
export const GET_TRANSITION_EMPLOYEE_CAT_DATA = "GET_TRANSITION_EMPLOYEE_CAT_DATA";
export const GET_TRANSITION_EMPLOYEE_CAT_START = "GET_TRANSITION_EMPLOYEE_CAT_START";
export const GET_TRANSITION_EMPLOYEE_CAT_SINGLE = "GET_TRANSITION_EMPLOYEE_CAT_SINGLE";
export const GET_TRANSITION_EMPLOYEE_CAT_END = "GET_TRANSITION_EMPLOYEE_CAT_END";

// HR OPERATIONS ===> EMPOYLEE TYPE
export const GET_TRANSITION_EMPOYLEE_TYPE_DATA = "GET_TRANSITION_EMPOYLEE_TYPE_DATA";
export const GET_TRANSITION_EMPOYLEE_TYPE_START = "GET_TRANSITION_EMPOYLEE_TYPE_START";
export const GET_TRANSITION_EMPOYLEE_TYPE_SINGLE = "GET_TRANSITION_EMPOYLEE_TYPE_SINGLE";
export const GET_TRANSITION_EMPOYLEE_TYPE_END = "GET_TRANSITION_EMPOYLEE_TYPE_END";

// HR OPERATIONS ===> POSITION
export const GET_TRANSITION_POSITION_DATA = "GET_TRANSITION_POSITION_DATA";
export const GET_TRANSITION_POSITION_START = "GET_TRANSITION_POSITION_START";
export const GET_TRANSITION_POSITION_SINGLE = "GET_TRANSITION_POSITION_SINGLE";
export const GET_TRANSITION_POSITION_END = "GET_TRANSITION_POSITION_END";

// HR OPERATIONS ===> GRADES
export const GET_TRANSITION_GRADES_DATA = "GET_TRANSITION_GRADES_DATA";
export const GET_TRANSITION_GRADES_START = "GET_TRANSITION_GRADES_START";
export const GET_TRANSITION_GRADES_SINGLE = "GET_TRANSITION_GRADES_SINGLE";
export const GET_TRANSITION_GRADES_END = "GET_TRANSITION_GRADES_END";

// HR OPERATIONS ===> REFRESHABLE DATA 
export const GET_TRANSITION_REFRESHABLE_DATA = "GET_TRANSITION_REFRESHABLE_DATA";
export const GET_TRANSITION_REFRESHABLE_DATA_START = "GET_TRANSITION_REFRESHABLE_DATA_START";
export const GET_TRANSITION_REFRESHABLE_DATA_SINGLE = "GET_TRANSITION_REFRESHABLE_DATA_SINGLE";
export const GET_TRANSITION_REFRESHABLE_DATA_END = "GET_TRANSITION_REFRESHABLE_DATA_END";

// HR OPERATIONS ===> GRADES
export const GET_DOWNLOAD_PARA_ACCESS_DATA = "GET_DOWNLOAD_PARA_ACCESS_DATA";
export const GET_DOWNLOAD_PARA_ACCESS_START = "GET_DOWNLOAD_PARA_ACCESS_START";
export const GET_DOWNLOAD_PARA_ACCESS_SINGLE = "GET_DOWNLOAD_PARA_ACCESS_SINGLE";
export const GET_DOWNLOAD_PARA_ACCESS_END = "GET_DOWNLOAD_PARA_ACCESS_END";
export const GET_SELECT_USER_DATA = "GET_SELECT_USER_DATA"



// PAYROLL ALLOWANCE ===>
export const GET_ALLOWANCE_START = "GET_ALLOWANCE_START";
export const GET_ALLOWANCE_COMPLETE = "GET_ALLOWANCE_COMPLETE";
export const GET_ALLOWANCE_END = "GET_ALLOWANCE_END";


// HR OPERATIONS ===> MASTER PERSONAL
export const GET_MASTER_PERSONAL_DATA = "GET_MASTER_PERSONAL_DATA";
export const GET_MASTER_PERSONAL_START = "GET_MASTER_PERSONAL_START";
export const GET_MASTER_PERSONALSINGLE = "GET_MASTER_PERSONALSINGLE";
export const GET_MASTER_PERSONAL_END = "GET_MASTER_PERSONAL_END";
// // HR OPERATIONS ===> MasterPersonel 
// export const GET_TRANSITION_MASTERPERSONEL_DATA = "GET_TRANSITION_MASTERPERSONEL_DATA";
// export const GET_TRANSITION_MASTERPERSONEL_DATA_START = "GET_TRANSITION_MASTERPERSONEL_DATA_START";
// export const GET_TRANSITION_MASTERPERSONEL_DATA_SINGLE = "GET_TRANSITION_REFRESHABLE_DATA_SINGLE";
// export const GET_TRANSITION_MASTERPERSONEL_DATA_END = "GET_TRANSITION_MASTERPERSONEL_DATA_END";

// HR OPERATIONS ===> Instituion 

export const GET_TRANSITION_INSTITUTION_DATA = "GET_TRANSITION_INSTITUTION_DATA";
export const GET_TRANSITION_INSTITUTION_DATA_START = "GET_TRANSITION_INSTITUTION_DATA_START";
export const GET_TRANSITION_INSTITUTION_DATA_SINGLE = "GET_TRANSITION_INSTITUTION_DATA_SINGLE";
export const GET_TRANSITION_INSTITUTION_DATA_END = "GET_TRANSITION_INSTITUTION_DATA_END";

// HR OPERATIONS ===> Holidays 

export const GET_HOLIDAYS_DATA = "GET_HOLIDAYS_DATA";
export const GET_HOLIDAYS_DATA_START = "GET_HOLIDAYS_DATA_START";
export const GET_HOLIDAYS_DATA_SINGLE = "GET_HOLIDAYS_DATA_SINGLE";
export const GET_HOLIDAYS_DATA_END = "GET_HOLIDAYS_DATA_END";


// HR OPERATIONS ===> LOCATION 

export const GET_LOCATION_DATA = "GET_LOCATION_DATA";
export const GET_LOCATION_DATA_START = "GET_LOCATION_DATA_START";
export const GET_LOCATION_DATA_SINGLE = "GET_LOCATION_DATA_SINGLE";
export const GET_LOCATION_DATA_END = "GET_LOCATION_DATA_END";


// HR OPERATIONS ===> POSITION 

export const GET_POSITION_DATA = "GET_POSITION_DATA";
export const GET_POSITION_DATA_START = "GET_POSITION_DATA_START";
export const GET_POSITION_DATA_SINGLE = "GET_POSITION_DATA_SINGLE";
export const GET_POSITION_DATA_END = "GET_POSITION_DATA_END";

// MASTER MAINTAINCE ===> MASTER_EARNING 

export const GET_MASTER_EARNING_DATA = "GET_POSITION_DATA";
export const GET_MASTER_EARNING_DATA_START = "GET_POSITION_DATA_START";
export const GET_MASTER_EARNING_DATA_SINGLE = "GET_POSITION_DATA_SINGLE";
export const GET_MASTER_EARNING_DATA_END = "GET_POSITION_DATA_END";


// PAYROLL CALCULATION ==========================
export const GET_PAYROLL_CAL_DATA = "GET_PAYROLL_CAL_DATA";
export const GET_PAYROLL_CAL_DATA_START = "GET_PAYROLL_CAL_DATA_START";
export const GET_PAYROLL_CAL_DATA_SINGLE = "GET_PAYROLL_CAL_DATA_SINGLE";
export const GET_PAYROLL_CAL_DATA_END = "GET_PAYROLL_CAL_DATA_END";

// HR OPERATIONS ===> Leave_Category 

export const GET_TRANSITION_LEAVE_CATEGORY_DATA = "GET_TRANSITION_LEAVE_CATEGORY_DATA";
export const GET_TRANSITION_LEAVE_CATEGORY_DATA_START = "GET_TRANSITION_LEAVE_CATEGORY_DATA_START";
export const GET_TRANSITION_LEAVE_CATEGORY_DATA_SINGLE = "GET_TRANSITION_LEAVE_CATEGORY_DATA_SINGLE";
export const GET_TRANSITION_LEAVE_CATEGORY_DATA_END = "GET_TRANSITION_LEAVE_CATEGORY_DATA_END";

// HR OPERATIONS ===> Religion === 

export const GET_TRANSITION_RELIGION_DATA = "GET_TRANSITION_RELIGION_DATA";
export const GET_TRANSITION_RELIGION_DATA_START = "GET_TRANSITION_RELIGION_DATA_START";
export const GET_TRANSITION_RELIGION_DATA_SINGLE = "GET_TRANSITION_RELIGION_DATA_SINGLE";
export const GET_TRANSITION_RELIGION_DATA_END = "GET_TRANSITION_RELIGION_DATA_END";

// HR OPERATIONS ===> Resignation === 
export const GET_TRANSITION_RESIGNATION_DATA = "GET_TRANSITION_RESIGNATION_DATA";
export const GET_TRANSITION_RESIGNATION_DATA_START = "GET_TRANSITION_RESIGNTION_DATA_START";
export const GET_TRANSITION_RESIGNATION_DATA_SINGLE = "GET_TRANSITION_RESIGNATION_DATA_SINGLE";
export const GET_TRANSITION_RESIGNATION_DATA_END = "GET_TRANSITION_RESIGNATION_DATA_END";

// HR OPERATIONS ===> Section === 
export const GET_TRANSITION_SECTION_DATA = "GET_TRANSITION_SECTION_DATA";
export const GET_TRANSITION_SECTION_DATA_START = "GET_TRANSITION_SECTION_DATA_START";
export const GET_TRANSITION_SECTION_DATA_SINGLE = "GET_TRANSITION_SECTION_DATA_SINGLE";
export const GET_TRANSITION_SECTION_DATA_END = "GET_TRANSITION_SECTION_DATA_END";

// HR OPERATIONS ===> Previos Employer === 
export const GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA = "GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA";
export const GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA_START = "GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA_START";
export const GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA_SINGLE = "GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA_SINGLE";
export const GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA_END = "GET_TRANSITION_PERVIOUS_EMPLOYEE_DATA_END";

// HR OPERATIONS ===> Leave Type === 
export const GET_TRANSITION_LEAVE_TYPE_DATA = "GET_TRANSITION_LEAVE_TYPE_DATA";
export const GET_TRANSITION_LEAVE_TYPE_DATA_START = "GET_TRANSITION_LEAVE_TYPE_DATA_START";
export const GET_TRANSITION_LEAVE_TYPE_DATA_SINGLE = "GET_TRANSITION_LEAVE_TYPE_DATA_SINGLE";
export const GET_TRANSITION_LEAVE_TYPE_DATA_END = "GET_TRANSITION_LEAVE_TYPE_DATA_END";

// MASTER MAINTAINCE ===> MASTER ACTIVE === 
export const GET_MASTER_ACTIVE_DATA = "GET_MASTER_ACTIVE_DATA";
export const GET_MASTER_ACTIVE_DATA_START = "GET_MASTER_ACTIVE_DATA_START";
export const GET_MASTER_ACTIVE_DATA_SINGLE = "GET_MASTER_ACTIVE_DATA_SINGLE";
export const GET_MASTER_ACTIVE_DATA_END = "GET_MASTER_ACTIVE_DATA_END";


// PAY SLIP ===>  
export const GET_PAY_SLIP_PDF_DATA = "GET_PAY_SLIP_PDF_DATA";
export const GET_PAY_SLIP_PDF_DATA_START = "GET_PAY_SLIP_PDF_DATA_START";
export const GET_PAY_SLIP_PDFDATA_SINGLE = "GET_PAY_SLIP_PDFDATA_SINGLE";
export const GET_PAY_SLIP_PDF_DATA_END = "GET_PAY_SLIP_PDF_DATA_END";

// PAY SLIP ===>  
export const GET_ATTENDANCE_SHEET_DATA = "GET_ATTENDANCE_SHEET_DATA";
export const GET_ATTENDANCE_SHEET_START = "GET_ATTENDANCE_SHEET_START";
export const GET_ATTENDANCE_SHEET_SINGLE = "GET_ATTENDANCE_SHEET_SINGLE";
export const GET_ATTENDANCE_SHEET_END = "GET_ATTENDANCE_SHEET_END";

// MANUAL LEAVE POSTING ====> LEAVE
export const GET_MANUAL_LEAVE_POSTING_DATA = "GET_MANUAL_LEAVE_POSTING_DATA";
export const GET_MANUAL_LEAVE_POSTING_DATA_START = "GET_MANUAL_LEAVE_POSTING_DATA_START";
export const GET_MANUAL_LEAVE_POSTING_DATA_SINGLE = "GET_MANUAL_LEAVE_POSTING_DATA_SINGLE";
export const GET_MANUAL_LEAVE_POSTING_DATA_END = "GET_MANUAL_LEAVE_POSTING_DATA_END";
export const GET_EMP_DATA = "GET_EMP_DATA";
export const GET_LEAVE_TYPE_DATA = "GET_LEAVE_TYPE_DATA";
export const SET_EMP_BALANCE_DAYS = "SET_EMP_BALANCE_DAYS";
export const APPLIED_EMP_DAYS_DATA = "APPLIED_EMP_DAYS_DATA";
export const GET_MY_LEAVE_EMP_APPLICATION = "GET_MY_LEAVE_EMP_APPLICATION";


// Setup ===> Jv_Code === 
export const GET_JV_Code_DATA = "GET_JV_Code_DATA";
export const GET_JV_Code_DATA_START = "GET_JV_Code_DATA_START";
export const GET_JV_Code_DATA_SINGLE = "GET_JV_Code_DATA_SINGLE";
export const GET_JV_Code_DATA_END = "GET_JV_Code_DATA_END";

// Setup ===> IncomeTax_Columns === 
export const GET_IncomeTax_Columns_DATA = "GET_IncomeTax_Columns_DATA";
export const GET_IncomeTax_Columns_DATA_START = "GET_IncomeTax_Columns_DATA_START";
export const GET_IncomeTax_Columns_DATA_SINGLE = "GET_IncomeTax_Columns_DATA_SINGLE";
export const GET_IncomeTax_Columns_DATA_END = "GET_IncomeTax_Columns_DATA_END";

// Setup ===> Bank_Branches === 
export const GET_Bank_Branches_DATA = "GET_Bank_Branches_DATA";
export const GET_Bank_Branches_DATA_START = "GET_Bank_Branches_DATA_START";
export const GET_Bank_Branches_DATA_SINGLE = "GET_Bank_Branches_DATA_SINGLE";
export const GET_Bank_Branches_DATA_END = "GET_Bank_Branches_DATA_END";

// Appointment ===>Appointment  === 
export const GET_APPOINTMENT_DATA = "GET_APPOINTMENT_DATA";
export const GET_APPOINTMENT_DATA_START = "GET_APPOINTMENT_DATA_START";
export const GET_APPOINTMENT_DATA_SINGLE = "GET_APPOINTMENT_DATA_SINGLE";
export const GET_APPOINTMENT_DATA_END = "GET_APPOINTMENT_DATA_END";


// PAYROLL ===> TAX_STRUCTURE === 
export const GET_TAX_STRUCTURE_DATA = "GET_TAX_STRUCTURE_DATA";
export const GET_TAX_STRUCTURE_DATA_START = "GET_TAX_STRUCTURE_DATA_START";
export const GET_TAX_STRUCTURE_DATA_SINGLE = "GET_TAX_STRUCTURE_DATA_SINGLE";
export const GET_TAX_STRUCTURE_DATA_END = "GET_TAX_STRUCTURE_DATA_END";


// PAYROLL ===> Bank === 
export const GET_Bank_DATA = "GET_Bank_DATA";
export const GET_Bank_DATA_START = "GET_Bank_DATA_START";
export const GET_Bank_DATA_SINGLE = "GET_Bank_DATA_SINGLE";
export const GET_Bank_DATA_END = "GET_Bank_DATA_END";

// PAYROLL ===> Outstanding_Recoveries === 
export const GET_Outstanding_Recoveries_DATA = "GET_Outstanding_Recoveries_DATA";
export const GET_Outstanding_Recoveries_START = "GET_Outstanding_Recoveries_START";
export const GET_Outstanding_Recoveries_SINGLE = "GET_Outstanding_RecoveriesSINGLE";
export const GET_Outstanding_Recoveries_END = "GET_Outstanding_Recoveries_END";

// PAYROLL ===> Payroll_Catery_Access === 
export const GET_Payroll_Catery_Access_DATA = "GET_Payroll_Catery_Access_DATA";
export const GET_Payroll_Catery_Access_START = "GET_Payroll_Catery_Access_START";
export const GET_Payroll_Catery_Access_SINGLE = "GET_Payroll_Catery_Access_SINGLE";
export const GET_Payroll_Catery_Access_END = "GET_Payroll_Catery_Access_END";

// HRSETUPS ===> LATE ARRIVAL === 
export const GET_LATE_ARRIVAL_DATA = "GET_LATE_ARRIVAL_DATA";
export const GET_LATE_ARRIVAL_DATA_START = "GET_LATE_ARRIVAL_DATA_STAR";
export const GET_LATE_ARRIVAL_DATA_SINGLE = "GET_LATE_ARRIVAL_DATA_SINGLE";
export const GET_LOCATIONS_DATA = "GET_LOCATIONS_DATA";
export const GET_SECTION_DATA = "GET_SECTION_DATA";
export const GET_DIVISION_DATA = "GET_DIVISION_DATA";
export const GET_LATE_ARRIVAL_DATA_END = "GET_LATE_ARRIVAL_DATA_END";

// ADDMINSRATION ===> USER PROFILE === 
export const GET_CHANGE_PROFILE_DATA = "GET_CHANGE_PROFILE_DATA";
export const GET_CHANGE_PROFILE_DATA_START = "GET_CHANGE_PROFILE_DATA_START";
export const GET_CHANGE_PROFILE_DATA_SINGLE = "GET_CHANGE_PROFILE_DATA_SINGLE";
export const GET_CHANGE_PROFILE_DATA_END = "GET_CHANGE_PROFILE_DATA_END";

// HRSETUPS ===> New_Appointment_Report === 
export const GET_New_Appointment_Report_DATA = "GET_New_Appointment_Report_DATA";
export const GET_New_Appointment_Report_START = "GET_New_Appointment_Report_START";
export const GET_New_Appointment_Report_END = "GET_New_Appointment_Report_END";

// LOGIN SCREEN ===> FORGET PASSWORD === 
export const GET_FORGET_PASSWORD_DATA = "GET_FORGET_PASSWORD_DATA";
export const GET_FORGET_PASSWORD_DATA_START = "GET_FORGET_PASSWORD_DATA_START";
export const GET_FORGET_PASSWORD_DATA_SINGLE = "GET_FORGET_PASSWORD_DATA_SINGLE";
export const GET_FORGET_PASSWORD_DATA_END = "GET_FORGET_PASSWORD_DATA_END";
// MASTER MAINTENANCE  ===> Confirmation === 
export const GET_CONFORMATION_DATA = "GET_CONFORMATION_DATA";
export const GET_CONFORMATION_DATA_START = "GET_CONFORMATION_DATA_START";
export const GET_CONFORMATION_DATA_SINGLE = "GET_CONFORMATION_DATA_SINGLE";
export const GET_CONFORMATION_DATA_END = "GET_CONFORMATION_DATA_END";
export const GET_CONFORMATION_DATA_WAITING = "GET_CONFORMATION_DATA_WAITING";


// ADDMINSRATION ===> Access_Control === 
export const GET_Access_Control_DATA = "GET_Access_Control_DATA";
export const GET_Access_Control_DATA_START = "GET_Access_Control_DATA_START";
export const GET_Access_Control_DATA_SINGLE = "GET_Access_Control_DATA_SINGLE";
export const GET_Access_Control_DATA_END = "GET_Access_Control_DATA_END";
export const GET_ALL_MENUS = "GET_ALL_MENUS";

// HR SETUP ===========> DUE_FOR_CONFIRMATION =========
export const GET_DUE_FOR_CONFIRMATION_DATA = "GET_DUE_FOR_CONFIRMATION_DATA";
export const GET_DUE_FOR_CONFIRMATION_DATA_START = "GET_DUE_FOR_CONFIRMATION_DATA_START";
export const GET_DUE_FOR_CONFIRMATION_DATA_SINGLE = "GET_DUE_FOR_CONFIRMATION_DATA_SINGLE";
export const GET_DUE_FOR_CONFIRMATION_DATA_END = "GET_DUE_FOR_CONFIRMATION_DATA_END";


// HR APPOINTMENT EDUCATION FORM //
export const GET_EDUCATIION_INFO_DATA = "GET_EDUCATIION_INFO_DATA";
export const GET_EDUCATIION_INFO_START = "GET_EDUCATIION_INFO_START";
export const GET_EDUCATIION_GRADE_DATA = "GET_EDUCATIION_GRADE_DATA";
export const GET_EDUCATIION_INSTITUTE_DATA = "GET_EDUCATIION_INSTITUTE_DATA";
export const GET_EDUCATIION_EDUCATION_DATA = "GET_EDUCATIION_EDUCATION_DATA";
export const GET_EDUCATIION_EDUCATION_SAVED_DATA = "GET_EDUCATIION_EDUCATION_SAVED_DATA";
export const GET_EDUCATIION_INFO_SINGLE = "GET_EDUCATIION_INFO_SINGLE";
export const GET_EDUCATIION_INFO_END = "GET_EDUCATIION_INFO_END";

// HRSETUPS ===> New_Appointment_Report === 
export const GET_Retirement_Due_Report_DATA = "GET_Retirement_Due_Report_DATA";
export const GET_Retirement_Due_Report_START = "GET_Retirement_Due_Report_START";
export const GET_Retirement_Due_Report_END = "GET_Retirement_Due_Report_END";

// HR SETUP ===========> ServiceLengthReport =========
export const GET_ServiceLengthReport_DATA = "GET_ServiceLengthReport_DATA";
export const GET_ServiceLengthReport_DATA_START = "GET_ServiceLengthReport_DATA_START";
export const GET_ServiceLengthReport_DATA_SINGLE = "GET_ServiceLengthReport_DATA_SINGLE";
export const GET_ServiceLengthReport_DATA_END = "GET_ServiceLengthReport_DATA_END";



// HR APPOINTMENT EXPRIENCE FORM =======
export const GET_EXPRIENCE_INFO_DATA = "GET_EXPRIENCE_INFO_DATA";
export const GET_EXPRIENCE_INFO_START = "GET_EXPRIENCE_INFO_START";
export const GET_EXPRIENCE_EMP_DATA = "GET_EXPRIENCE_EMP_DATA";
export const GET_EXPRIENCE_EMPlOYER_DATA = "GET_EXPRIENCE_EMPlOYER_DATA";
export const GET_EXPRIENCE_INFO_SINGLE = "GET_EXPRIENCE_INFO_END";
export const GET_EXPRIENCE_INFO_END = "GET_EXPRIENCE_INFO_END";

// HR APPOINTMENT SALARY FORM =======
export const GET_SALARY_DATA = "GET_SALARY_DATA";
export const GET_SALARY_START = "GET_SALARY_START";
export const GET_SALARY_ALLOWANCE_DATA = "GET_SALARY_ALLOWANCE_DATA";
export const GET_SALARY_AMOUNT_DATA = "GET_SALARY_AMOUNT_DATA";
export const GET_SALARY_SINGLE = "GET_SALARY_SINGLE";
export const GET_SALARY_END = "GET_SALARY_END";


// APPOINTMENT Payroll  FORM ========
export const GET_APPOIN_PAYROLL_DATA = "GET_APPOIN_PAYROLL_DATA";
export const GET_APPOIN_PAYROLL_DATA_START = "GET_APPOIN_PAYROLL_DATA_START";
export const GET_APPOIN_PAYROLL_INFO_DATA = "GET_APPOIN_PAYROLL_INFO_DATA";
export const GET_APPOIN_PAYROLL_BANK_BRANCHES_DATA = "GET_APPOIN_PAYROLL_BANK_BRANCHES_DATA";
export const GET_APPOIN_PAYROLL_DATA_SINGLE = "GET_APPOIN_PAYROLL_DATA_SINGLE";
export const GET_APPOIN_PAYROLL_DATA_END = "GET_APPOIN_PAYROLL_DATA_END";


// APPOINTMENT Family Form =====================
export const GET_FAMILY_DATA_START = "GET_FAMILY_DATA_START";
export const GET_FAMILY_DATA = "GET_FAMILY_DATA";
export const GET_FAMILY_MARRIAGE_DATA = "GET_FAMILY_MARRIAGE_DATA";
export const GET_FAMILY_CHILDREN_DATA = "GET_FAMILY_CHILDREN_DATA";
export const GET_FAMILY_DATA_SINGLE = "GET_FAMILY_DATA_SINGLE";
export const GET_FAMILY_DATA_END = "GET_FAMILY_DATA_END";

// HR SETUP ===========> TranEducationReport =========
export const GET_TranEducationReport_DATA = "GET_TranEducationReport_DATA";
export const GET_TranEducationReport_DATA_START = "GET_TranEducationReport_DATA_START";
export const GET_TranEducationReport_DATA_SINGLE = "GET_TranEducationReport_DATA_SINGLE";
export const GET_TranEducationReport_DATA_END = "GET_TranEducationReport_DATA_END";


// HRSETUPS ===> Experience_Report === 
export const GET_Experience_Report_DATA = "GET_Experience_Report_DATA";
export const GET_Experience_Report_START = "GET_Experience_Report_START";
export const GET_Experience_Report_END = "GET_Experience_Report_END";

// EMPOLYEE LEAVES ================================================
export const GET_EMP_LEAVE_DATA_START = "GET_EMP_LEAVE_DATA_START";
export const GET_EMP_LEAVES_DATA = "GET_EMP_LEAVES_DATA";
export const GET_EMP_LEAVE_DATA_END = "GET_EMP_LEAVE_DATA_END";
export const GET_EMP_LAVES_NAME_DATA = "GET_EMP_LAVES_NAME_DATA";
export const GET_EMP_LEAVE_DATA_SINGLE = "GET_EMP_LEAVE_DATA_SINGLE";
export const GET_EMP_LEAVES_TYPE_DATA = "GET_EMP_LEAVES_TYPE_DATA"
export const GET_EMP_LEAVES_APPLIED = "GET_EMP_LEAVES_APPLIED"
export const GET_EMP_LEAVES_BALANCED_DAYS = "GET_EMP_LEAVES_BALANCED_DAYS"
export const GET_EMP_LEAVES_APPLICATIONS = "GET_EMP_LEAVES_APPLICATIONS"
export const GET_EMP_LEAVES_ATTACEMENTS = "GET_EMP_LEAVES_ATTACEMENTS"


// HR SETUP ===========> RetirementSeparationReport =========
export const GET_RetirementSeparationReport_DATA = "GET_RetirementSeparationReport_DATA";
export const GET_RetirementSeparationReport_DATA_START = "GET_RetirementSeparationReport_DATA_START";
export const GET_RetirementSeparationReport_DATA_SINGLE = "GET_RetirementSeparationReport_DATA_SINGLE";
export const GET_RetirementSeparationReport_DATA_END = "GET_RetirementSeparationReport_DATA_END";



// Appointment===> check list === >

export const GET_CHECKLIST_DATA = "GET_CHECKLIST_DATA";
export const GET_CHECKLIST_DATA_START = "GET_CHECKLIST_DATA_START";
export const GET_CHECKLIST_DATA_CHECK = "GET_CHECKLIST_DATA_SINGLE";
export const GET_CHECKLIST_DATA_END = "GET_CHECKLIST_DATA_END";


// HRSETUPS ===> Date_Of_Birth_Inquiry_Report === 

export const GET_Date_Of_Birth_Inquiry_Report_DATA = "Date_Of_Birth_Inquiry_Report_DATA,";
export const GET_Date_Of_Birth_Inquiry_Report_START = "GET_Date_Of_Birth_Inquiry_Report_START";
export const GET_Date_Of_Birth_Inquiry_Report_END = "GET_Date_Of_Birth_Inquiry_Report_END";

// PAYROLL ===> Bank_Letter_Report === 

export const GET_Bank_Letter_Report_DATA = "GET_Bank_Letter_Report_DATA,";
export const GET_Bank_Letter_Report_Payroll_Data = "GET_Bank_Letter_Report_Payroll_Data";
export const GET_Bank_Letter_Report_Region_Data = "GET_Bank_Letter_Report_Region_Data";
export const GET_Bank_Letter_Report_Bank_Data = "GET_Bank_Letter_Report_Bank_Data";
export const GET_Bank_Letter_Report_Type_Data = "GET_Bank_Letter_Report_Bank_Data";
export const GET_Bank_Letter_Report_START = "GET_Bank_Letter_Report_START";
export const GET_Bank_Letter_Report_END = "GET_Bank_Letter_Report_END";

// PAYROLL ===> Paysheet_Report === 

export const GET_PAYSHEET_Report_DATA = "GET_PAYSHEET_Report_DATA,";
export const GET_PAYSHEET_Report_START = "GET_PAYSHEET_Report_START";
export const GET_PAYSHEET_Report_PAYROLL = "GET_PAYSHEET_Report_PAYROLL";
export const GET_PAYSHEET_Report_END = "GET_PAYSHEET_Report_END";


// ========== ======= ===== MANUAL ATTENDANCE======== ===== === /
export const GET_MANUAL_ATTENDANCE_DATA = "GET_MANUAL_ATTENDANCE_DATA";
export const GET_MANUAL_ATTENDANCE_DATA_START = "GET_MANUAL_ATTENDANCE_DATA_START";
export const GET_MANUAL_ATTENDANCE_DATA_INFO = "GET_MANUAL_ATTENDANCE_DATA_INFO";
export const GET_MANUAL_ATTENDANCE_DATA_SINGLE = "GET_MANUAL_ATTENDANCE_DATA_SINGLE";
export const GET_MANUAL_ATTENDANCE_DATA_END = "GET_MANUAL_ATTENDANCE_DATA_END"; 


// EMP LEAVES APPROVALS TYPES =========================
export const GET_EMP_APPROVALS_DATA_START = "GET_EMP_APPROVALS_DATA_START"
export const GET_EMP_APPROVALS_ALL_DATA = "GET_EMP_APPROVALS_ALL_DATA"
export const GET_EMP_APPROVALS_DATA_END = "GET_EMP_APPROVALS_DATA_END"
export const GET_LEAVE_SUMMERY_BY_ID = "GET_LEAVE_SUMMERY_BY_ID"
export const GET_LEAVE_SUMMERY_FILE_BY_ID = "GET_LEAVE_SUMMERY_FILE_BY_ID"


// LEAVE REPORT BALANCED TYPES ==============================
export const GET_LEAVE_REPORT_BALANCE_DATA_START = "GET_LEAVE_REPORT_BALANCE_DATA_START"
export const GET_LEAVE_REPORT_BALANCE_DATA_END = "GET_LEAVE_REPORT_BALANCE_DATA_END"
export const GET_LEAVE_REPORT_BALANCE_LEAVE_TYPES = "GET_LEAVE_REPORT_BALANCE_LEAVE_TYPES"
export const GET_LEAVE_REPORT_BALANCE_LEAVE_CAT = "GET_LEAVE_REPORT_BALANCE_LEAVE_CAT"
export const GET_LEAVE_REPORT_BALANCE_LEAVE_EMP = "GET_LEAVE_REPORT_BALANCE_LEAVE_EMP"
export const GET_LEAVE_REPORT_BALANCE_LEAVE_YEAR = "GET_LEAVE_REPORT_BALANCE_LEAVE_YEAR"


// LEAVE BALANCED UPLOAD TYPES ==============================
export const GET_LEAVE_BALANCE_UPLOAD_DATA_START = "GET_LEAVE_BALANCE_UPLOAD_DATA_START"
export const GET_LEAVE_BALANCE_UPLOAD_DATA_END = "GET_LEAVE_BALANCE_UPLOAD_DATA_END"
export const GET_LEAVE_BALANCE_UPLOAD_YEAR_DATA = "GET_LEAVE_BALANCE_UPLOAD_YEAR_DATA"

// Promotion =========================
export const GET_PROMOTION_DATA_START = "GET_PROMOTION_DATA_START"
export const GET_PROMOTION_DATA = "GET_PROMOTION_DATA"
export const GET_PROMOTION_INFO_DATA = "GET_PROMOTION_INFO_DATA"
export const GET_PROMOTION_CATEGORY_DATA = "GET_PROMOTION_CATEGORY_DATA"
export const GET_PROMOTION_DESIGNATION_DATA = "GET_PROMOTION_DESIGNATION_DATA"
export const GET_PROMOTION_GRADE_DATA = "GET_PROMOTION_GRADE_DATA"
export const GET_PROMOTION_SUPERVISOR_DATA = "GET_PROMOTION_SUPERVISOR_DATA"
export const GET_PROMOTION_COSTCENTER_DATA = "GET_PROMOTION_COSTCENTER_DATA"
export const GET_PROMOTION_WAITING_DATA = "GET_PROMOTION_WAITING_DATA"
export const GET_PROMOTION_BYID_DATA = "GET_PROMOTION_BYID_DATA"
export const GET_PROMOTION_DATA_END = "GET_PROMOTION_DATA_END"

// ========== MasterMaintaince ======= ===== Confirmation_Extensio2======== ===== === /
export const GET_Confirmation_Extension_DATA = "GET_Confirmation_Extension_DATA";
export const GET_Confirmation_Extension_START = "GET_Confirmation_Extension_START";
export const GET_Confirmation_Extension_Confirmation = "GET_Confirmation_Extension_Confirmation";
export const GET_Confirmation_Extension_END = "GET_Confirmation_Extension_END"; 

// ========== MasterMaintaince ======= ===== Increment ======== ===== === /
export const GET_Increment_DATA = "GET_Increment_DATA";
export const GET_Increment_START = "GET_Increment_START";
export const GET_Increment_Confirmation = "GET_Increment_Confirmation";
export const GET_Increment_END = "GET_Increment_END"; 

// PAYROLL ===> PaySlip === 

export const GET_PaySlip_Employee_Wise_DATA = "GET_PaySlip_Employee_Wise_DATA,";
export const GET_PaySlip_Employee_Wise_Employee = "GET_PaySlip_Employee_Wise_Employee";
export const GET_PaySlip_Employee_Wise_Payroll = "GET_PaySlip_Employee_Wise_Payroll";
export const GET_PaySlip_Employee_Wise_Employee_Category = "GET_PaySlip_Employee_Wise_Employee_Category";
export const GET_PaySlip_Employee_Wise_START = "GET_PaySlip_Employee_Wise_START";
export const GET_PaySlip_Employee_Wise_END = "GET_PaySlip_Employee_Wise_END";


// ========== MasterMaintaince ======= ===== TransactionResignation======== ===== === /
export const GET_TransactionResignation_DATA = "GET_TransactionResignation_DATA";
export const GET_TransactionResignation_START = "GET_TransactionResignation_START";
export const GET_TransactionResignation_Confirmation = "GET_TransactionResignation_Confirmation";
export const GET_TransactionResignation_Waiting = "GET_TransactionResignation_Waiting";
export const GET_TransactionResignation_END = "GET_TransactionResignation_END"; 



export const GET_SALARY_ON_HOLD_START = "GET_SALARY_ON_START";
export const GET_SALARY_ON_HOLD = "GET_SALARY_ON_HOLD";
export const GET_SALARY_ON_HOLD_END = "GET_SALARY_ON_HOLD_END";